import React from 'react';
import Navbar from './navbar';
import Footer from './Footer';

function LanDetails() {
  return (
    <div>
      <Navbar />
      <h1>Détails de la LAN Battlefield</h1>
      <p>Informations détaillées sur la LAN...</p>
      <Footer />
    </div>
  );
}

export default LanDetails;
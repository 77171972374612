import React, { useState } from 'react';
import './style/MiniMapInteractive.css';
import Map from './asset/El_Alamein_map intro.jpg';

function MiniMapInteractive() {
  const [selectedPoint, setSelectedPoint] = useState(null);

  const points = [
    { id: 1, name: 'Zone Alpha', x: '20%', y: '40%', description: 'Description de la Zone Alpha' },
    { id: 2, name: 'Zone Bravo', x: '80%', y: '60%', description: 'Description de la Zone Bravo' },
    { id: 3, name: 'Zone Charlie', x: '40%', y: '70%', description: 'Description de la Zone Charlie' }
  ];

  const handlePointClick = (point) => {
    setSelectedPoint(point);
  };

  const closeModal = () => {
    setSelectedPoint(null);
  };

  return (
    <div className="mini-map-container">
      <img src={Map} alt="Mini Map" className="mini-map-image" />

      {points.map((point) => (
        <div
          key={point.id}
          className="map-point"
          style={{ top: point.y, left: point.x }}
          onClick={() => handlePointClick(point)}
        />
      ))}

      {selectedPoint && (
        <div className="modal">
          <div className="modal-content">
            <h2>{selectedPoint.name}</h2>
            <p>{selectedPoint.description}</p>
            <button onClick={closeModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MiniMapInteractive;

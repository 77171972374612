import React, { useEffect } from 'react';
import './style/MotivationalSection.css';
import './style/MiniMapInteractive.css';

import Map from './MiniMapInteractive';

function MotivationalSection() {
  useEffect(() => {
    const handleScroll = () => {
      const motivationalSection = document.querySelector('.motivational-section');
      const sectionPosition = motivationalSection.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.5;

      if (sectionPosition < screenPosition) {
        motivationalSection.classList.add('in-view');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="motivational-section">
      
      <div className="motivational-container">
        <div className="map-section">
          <Map />
        </div>

        <div className="motivational-content">
          <p>
            Aujourd'hui, nous ne parlons pas de grandes idées stratégiques ou de conquête de territoires. 
            Non, nous sommes ici pour une seule raison : s'éclater ! Vous êtes à bord des véhicules les plus badass de la Seconde Guerre mondiale 
            et des machines de guerre modernes. C'est comme si Rambo rencontrait Iron Man dans un bar virtuel.
          </p>

          <p>
            Maintenant, camarades de bits et d'octets, dans cette folie virtuelle, souvenez-vous que derrière chaque avatar, il y a un camarade de jeu. 
            La camaraderie est notre force, même si, soyons honnêtes, on est tous juste là pour se taper sur la gueule et rigoler ensemble.
          </p>

          <p className="last-paragraph">
            Alors, que la bataille commence ! Montez dans ces engins de destruction, tirez avec ces armes délirantes, 
            mais surtout, ne prenez pas tout trop au sérieux. On est là pour s'amuser, pour créer des souvenirs épiques 
            qui nous feront sourire quand on repensera à cette folle journée de combat.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MotivationalSection;

import React, { useState } from 'react';
import './style/FormulaireInscription.css';

function FormulaireInscription() {
  const [email, setEmail] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [prenom, setPrenom] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  return (
    <div className="formulaire-inscription-container">
      <h2>S'INSCRIRE À LA LAN BATTLEFIELD 2024</h2>
      <p>Inscrivez-vous aujourd'hui pour recevoir les dernières infos, vidéos et programme de la LAN, entre autres, via e-mail.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-large"
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            id="pseudo"
            placeholder="Pseudo"
            value={pseudo}
            onChange={(e) => setPseudo(e.target.value)}
            required
            className="input-small"
          />
          <input
            type="text"
            id="prenom"
            placeholder="Prénom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            required
            className="input-small"
          />
        </div>

        <button type="submit" className="submit-button">S'inscrire</button>
      </form>

      {isSubmitted && (
        <div className="success-message">
          <p>Merci pour votre inscription, vous pouvez rejoindre notre Discord via le bouton ci-dessous !</p>
          <a href="https://discord.gg/ton-lien-discord" target="_blank" rel="noopener noreferrer">
            Rejoindre le Discord
          </a>
        </div>
      )}
    </div>
  );
}

export default FormulaireInscription;

import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './navbar';
import Footer from './Footer';
import war from './asset/BackgroundBF.jpg';
import './style/Home.css';
import Timer from './Timer';
import Section2 from './Section2';
import Section3 from './SectionBattlefield3';
import SectionMotivations from './MotivationalSection';
import DetailsVivre from './DetailsVivre';
import Compteur from './Compteur';
import Formulaire from './FormulaireInscription';

function Home() {
  return (
    <div id='home' className="home-container">
      <Navbar />
      <div className="image-overlay">
        <img src={war} alt='logo' className='logo' width={'100%'} id='img' />
        <div className="text-container">
          <h1>Bienvenue à vous Soldats</h1>
          <Timer />
          <br></br>
          <Link to="/LanDetails">S'engager</Link>
        </div>
      </div>
      <div className="transition-bar"></div>
      <div className="transition-text">
        <h2>Rejoignez la bataille !</h2>
      </div>
      <div id="section-compteurs">
        <Compteur />
      </div>
      <Section3 />
      <div id="section-discours">
        <SectionMotivations />
      </div>
      <Section2 />
      <div id="section-vivre-armee">
      <h2 className="food-section-title">Ravitaillement en Zone de Combat</h2>
    
        <DetailsVivre />
      </div>
      <Formulaire />
      <Footer />
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from 'react';
import './style/Timer.css'; 

function Timer() {
  const targetDate = new Date('November 18, 2024 00:00:00').getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <div className="timer-container">
      <h2 id='letter-timer'>Compte à rebours jusqu'à la LAN</h2>
      <div className="timer">
        <div className="time-unit">
          <span className="value">{timeRemaining.days}</span>
          <span className="label">Jours</span>
        </div>
        <div className="time-unit">
          <span className="value">{timeRemaining.hours}</span>
          <span className="label">Heures</span>
        </div>
        <div className="time-unit">
          <span className="value">{timeRemaining.minutes}</span>
          <span className="label">Minutes</span>
        </div>
        <div className="time-unit">
          <span className="value">{timeRemaining.seconds}</span>
          <span className="label">Secondes</span>
        </div>
      </div>
    </div>
  );
}

export default Timer;

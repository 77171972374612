import React, { useRef, useState } from 'react';
import './style/Section2.css';
import BF1 from './asset/BF1 carte.jpg';
import BF3 from './asset/BF3 carte.jpg';
import BF1942 from './asset/BF1942.jpg';
import BF4 from './asset/BF4 carte.jpg';
import TeamFortress from './asset/TeamFortresscarte.jpg';
import { Link } from 'react-router-dom';

const games = [
    { title: 'Battlefield 3', imgSrc: BF3, trailerUrl: 'https://www.youtube.com/watch?v=9DM7NsxOS0Q' },
    { title: 'Battlefield 4', imgSrc: BF4, trailerUrl: 'https://www.youtube.com/watch?v=wJ_F525yHVY' },
    { title: 'Battlefield 1', imgSrc: BF1, trailerUrl: 'https://www.youtube.com/watch?v=c7nRTF2SowQ' },
    { title: 'Battlefield 1942', imgSrc: BF1942, trailerUrl: 'https://www.youtube.com/watch?v=1Axog-9D5CA' },
    { title: 'Team Fortress', imgSrc: TeamFortress, trailerUrl: 'https://www.youtube.com/watch?v=N7ZafWA2jd8' },
];

function Section2() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-in');
    const visibleCards = 3; 

    const handleScroll = (direction) => {
        setFadeClass('fade-out');

        setTimeout(() => {
            if (direction === 'left') {
                setCurrentIndex((prevIndex) =>
                    prevIndex === 0 ? games.length - visibleCards : prevIndex - 1
                );
            } else {
                setCurrentIndex((prevIndex) =>
                    prevIndex === games.length - visibleCards ? 0 : prevIndex + 1
                );
            }

            setFadeClass('fade-in');
        }, 500); 
    };

    return (
        <div className="section2-container">
            <h2 className="section2-title">Terrains de Guerre : Nos Combats Légendaires</h2> {/* Titre mis à jour */}
            <div className="carousel-wrapper">
                <button className="carousel-button left" onClick={() => handleScroll('left')}>‹</button>
                <div className="carousel">
                    {games.slice(currentIndex, currentIndex + visibleCards).map((game, index) => (
                        <div key={index} className={`game-card ${fadeClass}`}>
                            <img src={game.imgSrc} alt={game.title} className="game-image" />
                            <h3 className="game-title">
                                <a id='title-game' href={game.trailerUrl} target="_blank" rel="noopener noreferrer">
                                    {game.title}
                                </a>
                            </h3>
                        </div>
                    ))}
                </div>
                <button className="carousel-button right" onClick={() => handleScroll('right')}>›</button>
            </div>
        </div>
    );
}

export default Section2;

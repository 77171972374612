
import React from 'react';
import { Link } from 'react-router-dom';
import error from './asset/404ErrorTank.jpg';
import './style/error404.css'
import Navbar from './navbar';
import Footer from './Footer';
function NoPage() {
    return (

        <div>
            <div className="image-container">
                <Navbar />
                <img src={error} alt='error404' className='image' />
                <div className="text-overlay">
                    <div className="text-container">
                    <p>Ce n'est pas par la le front Soldat !</p>
                    <Link  to="/">Back to Home</Link>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default NoPage;

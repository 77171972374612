import React, { useEffect, useRef } from 'react';
import './style/SectionBattlefield.css'; 
import BF3Image from './asset/BF3 carte.jpg';
import BF1Image from './asset/BF1 carte.jpg';

function SectionBattlefield() {
    const bf3Ref = useRef(null);
    const bf1Ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const bf3 = bf3Ref.current;
            const bf1 = bf1Ref.current;

            if (bf3 && bf3.getBoundingClientRect().top < window.innerHeight) {
                bf3.classList.add('visible');
            }
            if (bf1 && bf1.getBoundingClientRect().top < window.innerHeight) {
                bf1.classList.add('visible');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="section-bf3-container" ref={bf3Ref}>
                <div className="section-bf3-content">
                    <div className="section-bf3-image">
                        <img src={BF3Image} alt="Battlefield 3" />
                    </div>
                    <div className="section-bf3-text">
                        <h2>2011 - BATTLEFIELD 3 : un moment marquant qui tourne une page</h2>
                        <p>
                            Sorti en 2011, Battlefield 3 est un jeu vidéo de tir à la première personne 
                            qui a laissé une empreinte mémorable. Ce titre a marqué les lans, captivant 
                            les joueurs avec son expérience de guerre réaliste, ses graphismes époustouflants 
                            et ses mécaniques de jeu innovantes. Depuis sa sortie, Battlefield 3 continue de 
                            fidéliser les joueurs, offrant une expérience multijoueur intense et des souvenirs 
                            durables de batailles virtuelles.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-bf1-container" ref={bf1Ref}>
                <div className="section-bf1-content">
                    <div className="section-bf1-text">
                        <h2>2016 - BATTLEFIELD 1 : une immersion dans la Première Guerre mondiale</h2>
                        <p>
                            Battlefield 1 est sorti en 2016 et s'est distingué en mettant en avant la Première Guerre mondiale. 
                            Le jeu a impressionné par sa capacité à plonger les joueurs dans les champs de bataille historiques, 
                            avec une attention particulière aux détails et une atmosphère immersive. En recréant des combats épiques, 
                            il a redéfini la notion de guerre dans le monde vidéoludique, se différenciant par son approche 
                            plus réaliste et historiquement fidèle.
                        </p>
                    </div>
                    <div className="section-bf1-image">
                        <img src={BF1Image} alt="Battlefield 1" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionBattlefield;

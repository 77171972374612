import React, { useState, useEffect } from 'react';
import './style/Navbar.css';
import logo from './asset/LogoBF.png';
import { Link } from 'react-router-dom'; 
import Menu from './asset/Menu Hamburger.png';

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [hidden, setHidden] = useState(false); 

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > lastScrollTop) {
        setHidden(true);
      } else {
        setHidden(false);
      }

      if (scrollTop > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  
  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''} ${hidden ? 'hidden' : ''}`}>
      <Link to="/home"> 
        <img src={logo} alt="logo" className="logo-title" />
      </Link>
      <div className={`nav-links ${mobileMenuOpen ? 'mobile-menu' : ''}`}>
        <ul>
          <li className="active">
          <a href="#home">Home</a> 
          </li>
          <li>
            <a href="#section-compteurs">Division d'infanterie</a> 
          </li>
          <li>
            <a href="#section-discours">Carte de la Bataille</a> 
          </li>
          <li>
            <a href="#section-vivre-armee">Vivre de l'armée</a> 
          </li>
        </ul>
      </div>
      <img src={Menu} alt="menu-burger" className="menu-burger" onClick={handleMenuClick} />
    </nav>
  );
}

export default Navbar;

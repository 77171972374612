import React, { useEffect, useRef, useState } from 'react';
import './style/Footer.css';
import music from './music/Battlefield.mp3';
import LogoBF from './asset/SoldatBF3.png';

function Footer() {
  const audioRef = useRef(null);
  const [lastSavedPosition, setLastSavedPosition] = useState(0);

  useEffect(() => {
    const savedPosition = sessionStorage.getItem('audioPosition');

    if (savedPosition && audioRef.current) {
      const currentPosition = parseFloat(savedPosition);
      audioRef.current.currentTime = currentPosition;
      setLastSavedPosition(currentPosition);
    }

    const playAudio = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    };

    const updatePosition = () => {
      if (audioRef.current) {
        const currentPosition = audioRef.current.currentTime;
        setLastSavedPosition(currentPosition);
        sessionStorage.setItem('audioPosition', currentPosition.toString());
      }
    };

    document.addEventListener('click', playAudio);
    audioRef.current.addEventListener('timeupdate', updatePosition);

    return () => {
      document.removeEventListener('click', playAudio);

      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', updatePosition);
      }

      if (audioRef.current && window.performance.navigation.type === 1) {
        sessionStorage.setItem('audioPosition', audioRef.current.currentTime.toString());
      }
    };
  }, []);

  return (
    <footer>
      <img src={LogoBF} alt="logo" className="footer-logo-image" />
      <div className='footer-container1'>

        <div className="footer-container">

          <div className="footer-about">
            <h3>Événement : LAN Battlefield - Édition 2024</h3>
            <p>Rejoignez-nous pour une journée épique dédiée à Battlefield le 18 novembre en Ligne !

              <br></br>Au programme : des batailles intenses, des véhicules de guerre, et une ambiance décontractée. Que vous soyez novice ou vétéran, venez vous éclater et créer des souvenirs mémorables ensemble !

              <br></br>On se retrouve pour une journée de fun et de camaraderie !</p>
          </div>
          <div className="footer-categories">
            <h3>Categories</h3>
            <ul>
              <li>React</li>
              <li>JavaScript</li>
              <li>CSS</li>
              <li>GitHub</li>
            </ul>
          </div>
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#">Instagram</a></li>
              <li><a href="#">LinkedIn</a></li>
              <li><a href="#">GitHub</a></li>
            </ul>
          </div>
        </div>

        <hr />

        <div className="footer-copyright">
          <p>copyright© 2024 All Rights Reserverd By Armageddon</p>
        </div>
      </div>
      <audio
        id="background-music"
        controls
        loop
        ref={audioRef} style={{ display: 'none' }}
      >
        <source src={music} type="audio/mp3" />
      </audio>
    </footer>
  );
}

export default Footer;

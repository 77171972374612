import React from 'react';
import './style/Compteur.css';

function Compteurs() {
  return (
    <div className="compteurs-container">
      <div className="compteur-item">
        <div className="circle">
          <div className="circle-content">
            <h3>Sur place</h3>
            <p>11</p>
          </div>
        </div>
      </div>
      <div className="compteur-item">
        <div className="circle">
          <div className="circle-content">
            <h3>En ligne</h3>
            <p>7</p>
          </div>
        </div>
      </div>
      <div className="compteur-item">
        <div className="circle">
          <div className="circle-content">
            <h3>Absents</h3>
            <p>6</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compteurs;

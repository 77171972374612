import React from 'react';
import './style/NourritureDescription.css';
import img1 from './asset/Plan-Charcuterie.jpeg';  
import img2 from './asset/Plan-Bière.png.jpg';
import img3 from './asset/Plan-Burger.png';
import img4 from './asset/Plan-Fromage.jpg';
import img5 from './asset/Plan-Fruit.png';
import img6 from './asset/Plan-apperitif.png';

const VivreDeLArmeeListe = () => {
  return (
    <div className="food-description-simple-container">
      <div className="grid">
        <div className='card-grid'>
          <img src={img1} alt="Image 1" />
          <div className="description">
            <p>Préparez-vous pour une bataille de saveurs avec notre plateau de charcuterie ! Les saucissons s’affrontent, et chaque tranche est une tranchée de bonheur. À vos armes, ou plutôt, à vos couteaux !</p>
          </div>
        </div>
        <div className='card-grid'>
          <img src={img2} alt="Image 2" />
          <div className="description">
            <p>Et la bière, la vraie héroïne ! Chaque chope est une munition de bonne humeur. Levez vos verres, soldats, pour une offensive de convivialité. Que la mousse soit avec vous !</p>
          </div>
        </div>
        <div className='card-grid'>
          <img src={img3} alt="Image 3" />
          <div className="description">
            <p>C'est la bataille des titans ! D'un côté, le burger juteux, armé de fromage fondant ; de l'autre, la pizza croustillante, chargée comme un char d’assaut. Faites votre choix et préparez-vous à un assaut savoureux qui fera trembler votre estomac !</p>
          </div>
        </div>
        <div className='card-grid'>
          <img src={img4} alt="Image 4" />
          <div className="description">
            <p>Bienvenue dans la forteresse du fromage ! Du camembert crémeux au roquefort costaud, chaque bouchée est une attaque gustative. Prêts à lever le siège sur votre plateau ?</p>
          </div>
        </div>
        <div className='card-grid'>
          <img src={img5} alt="Image 5" />
          <div className="description">
            <p>Pour une trêve salutaire, nos crudités sont là ! Ces légumes croquants sont prêts à débarquer avec des sauces. Faites la paix avec votre assiette, les guerriers de la santé arrivent !</p>
          </div>
        </div>
        <div className='card-grid'>
          <img src={img6} alt="Image 6" />
          <div className="description">
            <p>Nos gâteaux d’apéro sont des combattants du goût ! Salés ou sucrés, ils vont déclencher une explosion de saveurs. Attention au dernier morceau, ça pourrait faire rage !</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VivreDeLArmeeListe;
